var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDialog === false && _vm.buttonInsideDialog === false
    ? _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _vm.isInsideDialog === false
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    icon: _vm.icon,
                    disabled: _vm.disabled,
                    color: "error",
                    loading: _vm.deleting,
                    depressed: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.deleteItem()
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("delete")
                  ]),
                  _vm._v(" " + _vm._s(_vm.buttonName) + " ")
                ],
                1
              )
            : _vm.isInsideDialog === true
            ? _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    disabled: _vm.disabled,
                    color: "error",
                    loading: _vm.deleting
                  },
                  on: {
                    click: function($event) {
                      return _vm.deleteItem()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.buttonName))]
              )
            : _vm._e(),
          !_vm.icon && _vm.isInsideDialog === false
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "secondary",
                    outlined: "",
                    exact: "",
                    text: "",
                    to: _vm.cancelRoute,
                    disabled: _vm.deleting
                  }
                },
                [_vm._v("cancel")]
              )
            : _vm._e()
        ],
        1
      )
    : _vm.showDialog === false && _vm.buttonInsideDialog === true
    ? _c(
        "v-btn",
        {
          attrs: {
            color: "error",
            icon: _vm.icon,
            disabled: _vm.disabled,
            loading: _vm.deleting,
            text: ""
          },
          on: {
            click: function($event) {
              return _vm.deleteItem()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.buttonName) + " ")]
      )
    : _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "mr-1",
                        attrs: {
                          block: "",
                          icon: _vm.icon,
                          disabled: _vm.disabled,
                          color: "primary",
                          text: "",
                          loading: _vm.deleting
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-center",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("delete")]
                          ),
                          _vm._v(" " + _vm._s(_vm.buttonName) + " ")
                        ],
                        1
                      )
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { staticClass: "mr-1" }, [_vm._v("delete")]),
                  _c("span", { staticClass: "primary--text" }, [
                    _vm._v(_vm._s(_vm.buttonName))
                  ])
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-1" }),
              _c("v-card-text", [_vm._v(_vm._s(_vm.warningText))]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.deleteItem()
                        }
                      }
                    },
                    [_vm._v("Delete")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }